@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#tsparticles {
  height: 100vh;
  width: 100vw;
}

input[type="range"] {
  /* removing default appearance */
  -webkit-appearance: none;
  appearance: none; 
  /* creating a custom design */
  width: 100%;
  cursor: pointer;
  outline: none;
  /*  slider progress trick  */
  overflow: hidden;
  border-radius: 16px;
  background-color: #f1f5f9;
}

/* Track: webkit browsers */
/* input[type="range"]::-webkit-slider-runnable-track {
  height: 15px;
  background: #ccc;
  border-radius: 16px;
} */

/* Track: Mozilla Firefox */
/* input[type="range"]::-moz-range-track {
  height: 15px;
  background: #ccc;
  border-radius: 16px;
} */

/* Thumb: webkit */
input[type="range"]::-webkit-slider-thumb {
  /* removing default appearance */
  -webkit-appearance: none;
  appearance: none; 
  /* opacity: 0; */
  /* creating a custom design */
  height: 10px;
  width: 10px;
  background-color: #fff;
  border-radius: 50%;
  border: 2px solid #70cba2;
  box-shadow: -407px 0 0 400px #70cba2;
}

.custom-checkbox {
  width: 1rem; /* Custom size */
  height: 1rem; /* Custom size */
  border-radius: 0.25rem; /* Custom rounded corners */
  border: 0.5px solid #9CA3AF; /* Custom border color */
  background-color: white; /* Custom background color */
  appearance: none; /* Remove default styling */
  cursor: pointer; /* Pointer cursor */
  position: relative;
}

.custom-checkbox:checked {
  background-color: #70CBA2; /* Custom checked background color */
  border-color:0.5px solid #70CBA2 ;
}

.custom-checkbox:checked::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
  width: 0.5rem;
  height:0.7rem;
  border: solid white;
  border-width: 0 2px 2px 0;
}


/* Thumb: Firefox */
/* input[type="range"]::-moz-range-thumb {
  height: 15px;
  width: 15px;
  background-color: #fff;
  border-radius: 50%;
  border: 1px solid #f50;
  box-shadow: -407px 0 0 400px #f50;
} */

/* 
* {
  scrollbar-width: thin;
  scrollbar-color: #6366f1 #c7d2fe;
}

*::-webkit-scrollbar {
  width: 12px;
  height: 6px;
}

*::-webkit-scrollbar-track {
  background: #c7d2fe;
}

*::-webkit-scrollbar-thumb {
  background-color: #6366f1;
  border-radius: 20px;
  border: 3px solid  #c7d2fe;
} */